export const formatNumber = (num: number): string => {
  const [int, dec] = `${Number.parseFloat(num?.toFixed(2))}`?.split('.');
  const formattedInt = int.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return dec ? `${formattedInt},${dec}` : formattedInt;
};

export const roundNumber = (number: number, roundingPrecision = 2) => {
  const roundingMultiplier = 10 ** roundingPrecision;

  return Number(
    (Math.floor(number * roundingMultiplier) / roundingMultiplier)?.toFixed(
      roundingPrecision
    )
  );
};

export function formatNumberLetters(
  num: number,
  from = 10000,
  maximumFractionDigits = 2,
  roundingPrecision?: number
): string {
  if (Math.abs(num) < from) {
    return String(roundNumber(num, roundingPrecision).toLocaleString('en-US'));
  }

  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits,
  }).format(num);
}
