import BigNumber from 'bignumber.js';

const DECIMALS_IN_USDT = 6;

export const usdtFromNumeric = (numeric: number) => {
  const value = new BigNumber(numeric);
  return value.dividedBy(new BigNumber(10).pow(DECIMALS_IN_USDT)).toString();
};

export const numericFromUsdt = (usdt: number): string => {
  const value = new BigNumber(usdt);
  return value.multipliedBy(new BigNumber(10).pow(DECIMALS_IN_USDT)).toString();
};
