import { toast } from 'App';
import { $api } from './interceptor';
import { AxiosError } from 'axios';
import { settingsStore } from 'views/store/useSettingsStore';

export type TAdminSettings = {
  type: ESettingTypesEnum;
  isEnabled: boolean;
  value?: number;
};

export enum ESettingTypesEnum {
  AUTO_APPROVE = 'auto-approve',
  USDT_WITHDRAW_MIN_AMOUNT = 'usdt_withdraw_min_amount',
}

const adminSettingsApi = () => {
  const getAdminSettings = async (
    type: ESettingTypesEnum
  ): Promise<TAdminSettings | null> => {
    try {
      const { data } = await $api.get<TAdminSettings>('api/admin-settings', {
        params: { type },
      });
      settingsStore.setState({ isAutoApproveEnabled: data.isEnabled });

      return data;
    } catch (error) {
      console.error(error);
      toast({
        title:
          error instanceof AxiosError
            ? error.response?.data.message || 'Error fetching admin settings'
            : 'Error fetching admin settings',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      return null;
    }
  };

  const updateAdminSettings = async (
    value: TAdminSettings
  ): Promise<TAdminSettings | null> => {
    try {
      const { data } = await $api.post<TAdminSettings>(
        'api/admin-settings',
        value
      );
      settingsStore.setState({ isAutoApproveEnabled: data.isEnabled });
      toast({
        title: 'Admin settings was successfully updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      return data;
    } catch (error) {
      console.error(error);
      toast({
        title:
          error instanceof AxiosError
            ? error.response?.data.message || 'Error updating admin settings'
            : 'Error updating admin settings',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      return null;
    }
  };

  return {
    getAdminSettings,
    updateAdminSettings,
  };
};

export const adminSettingsService = adminSettingsApi();
