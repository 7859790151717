import { AxiosError } from 'axios';
import { $api } from './interceptor';
import { toast } from 'App';
import { EBundleVariant, TBundle } from 'views/types/popup';

const popUpApi = () => {
  const formatBundle = (bundle: TBundle) => {
    const bundlesList = bundle.bundles;

    const formattedBundles = bundlesList.map(item => ({
      starsPrice: +item.starsPrice,
      pointsCount: +item.pointsCount,
      ticketsCount: +item.ticketsCount,
      giveawayTicketsCount: +item.giveawayTicketsCount,
    }));

    return {
      shopQuest: {
        ...bundle.shopQuest,
        type: EBundleVariant.COMMON,
      },
      bundles: formattedBundles,
    };
  };

  const getCommonPayload = (bundle: TBundle) => {
    const formattedBundle = formatBundle(bundle);
    const bundleSet = formattedBundle.bundles[0];

    return {
      ...bundle,
      bundle: bundleSet,
    };
  };

  const getCommonPopUps = async (): Promise<TBundle[]> => {
    try {
      const { data } = await $api.get<TBundle[]>('/api/shop-quest/list/COMMON');

      const formattedData = data.map(item => ({
        ...item,
        shopQuest: {
          ...item.shopQuest,
          type: EBundleVariant.COMMON,
        },
      }));

      return formattedData;
    } catch (error) {
      console.log(error);
    }
  };

  const deletePopUp = async (id: string) => {
    try {
      await $api.delete(`/api/shop-quest/${id}`);

      toast({
        title: 'Pop-up deleted',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getStat = async () => {
    try {
      return await $api.get(`/api/shop-quest/stat`);
    } catch (error) {
      console.log(error);
    }
  };

  const createCommonBundle = async (bundle: TBundle) => {
    const payload = getCommonPayload(bundle);
    if (!payload.shopQuest.endDate) {
      payload.shopQuest.endDate = null;
    }
    try {
      await $api.post(`/api/shop-quest/common`, payload);

      toast({
        title: `${bundle.shopQuest.type} Pop-up '${bundle.shopQuest.title}' created`,
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        let message = error.response?.data.message || 'Something went wrong';
        const isMessageArray = Array.isArray(message);

        if (isMessageArray) {
          message = message.join(', ');
        }

        if (message.includes('At least one of pointsCount')) {
          message =
            'At least one field in the "Bundle Sets" section ("Stars", "Tickets", or "Golden Tickets") must be set to 1 or higher';
        }

        toast({
          title: message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }

      throw error;
    }
  };

  const updateCommonBundle = async (bundle: TBundle) => {
    const payload = getCommonPayload(bundle);
    if (!payload.shopQuest.endDate) {
      payload.shopQuest.endDate = null;
    }
    try {
      await $api.put(`/api/shop-quest/common/${bundle.shopQuest.id}`, payload);

      toast({
        title: `${bundle.shopQuest.type} Pop-up '${bundle.shopQuest.title}' updated`,
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        let message = error.response?.data.message || 'Something went wrong';
        const isMessageArray = Array.isArray(message);

        if (isMessageArray) {
          message = message.join(', ');
        }

        if (message.includes('At least one of pointsCount')) {
          message =
            'At least one field in the "Bundle Sets" section ("Stars", "Tickets", or "Golden Tickets") must be set to 1 or higher';
        }

        toast({
          title: message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }

      throw error;
    }
  };

  return {
    getCommonPopUps,
    deletePopUp,
    createCommonBundle,
    updateCommonBundle,
    getStat,
  };
};

export const popUpService = popUpApi();
